import { useState, useCallback } from "react"
import Link from "next/link"
import Image from "next/image"
import cn from "classnames"
import useWindowSize from "utilities/useWindowSize"
import richText from "utilities/richText"
import Arrow from "public/assets/right-arrow.svg"
import { getStoryblokLink } from "utilities/getStoryblokLink"

export default function LogoCarouselSection({ blok }) {
  const { width } = useWindowSize()
  const visibleSlides = useCallback(() => {
    if (width >= 1024 && blok.logos.length >= 4) return 6
    if (width >= 768 && blok.logos.length >= 3) return 3
    if (width >= 640 && blok.logos.length >= 2) return 2
    return 1
  }, [blok.logos.length, width])

  const [currentSlide, setSlide] = useState(0)

  const slideWidth = 141

  function moveSlidesLeft() {
    currentSlide !== 0
      ? setSlide(currentSlide - slideWidth)
      : setSlide(blok.logos.length * slideWidth - visibleSlides() * slideWidth)
  }

  function moveSlidesRight() {
    currentSlide <= (blok.logos.length - 1 - visibleSlides()) * slideWidth
      ? setSlide(currentSlide + slideWidth)
      : setSlide(0)
  }

  return (
    <section
      className={cn("px-5 py-8", {
        "bg-white": blok.background_color === "white",
        "bg-grey-cool-tint": blok.background_color === "grey-cool-tint",
      })}
    >
      <h2 className="text-center">{blok.heading}</h2>
      {blok.text ? (
        <div className="max-w-xl mx-auto my-2 text-center prose prose-p:text-left prose-p:text-black">
          {richText(blok.text)}
        </div>
      ) : null}
      <div className="mx-auto items-center max-w-screen-xl flex flex-col justify-center pt-12 mb-12 relative">
        <div className="relative overflow-x-hidden max-w-4xl overflow-y-hidden mx-auto">
          <div
            className="flex gap-4 items-center transition-all duration-700"
            style={{
              transform: `translateX(-${currentSlide}px)`,
              width: `${slideWidth * visibleSlides()}px`,
            }}
            aria-live="off"
          >
            {blok.logos.map((logo, idx) => {
              return getStoryblokLink(logo.link) ? (
                <Link
                  href={getStoryblokLink(logo.link)}
                  target="_blank"
                  key={idx}
                  alt={logo.image.alt}
                  title={logo.image.title}
                >
                  <div
                    className="min-w-[125px] h-[108px]"
                    aria-roledescription="slide"
                    role="group"
                    aria-label={`${idx + 1} of ${blok.logos.length}`}
                  >
                    <Image
                      className="h-full w-max object-contain mx-auto"
                      src={logo.image.filename}
                      alt={logo.image.alt}
                      width={108}
                      height={108}
                    />
                  </div>
                </Link>
              ) : (
                <div
                  className="min-w-[125px] h-[108px]"
                  aria-roledescription="slide"
                  key={idx}
                  role="group"
                  aria-label={`${idx + 1} of ${blok.logos.length}`}
                >
                  <Image
                    className="h-full w-max object-contain mx-auto"
                    src={logo.image.filename}
                    alt={logo.image.alt}
                    title={logo.image.title}
                    width={108}
                    height={108}
                  />
                </div>
              )
            })}
          </div>
        </div>
        {visibleSlides() < blok.logos.length ? (
          <div>
            <button onClick={moveSlidesLeft} className="absolute left-0 top-1/2 " aria-label="Previous Slide">
              <Arrow className="carousel-arrow rotate-180 text-secondary hover:text-tertiary" />
            </button>
            <button onClick={moveSlidesRight} className="absolute right-0 top-1/2 " aria-label="Next Slide">
              <Arrow className="carousel-arrow text-secondary hover:text-tertiary" />
            </button>
          </div>
        ) : null}
      </div>
    </section>
  )
}
